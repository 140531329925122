export class SubscriptionEnum {

	public static readonly BLOCKED: any = { id: 0, name: "BLOCKED" };
	public static readonly BASIC: any = { id: 1, name: "BASIC" };
	public static readonly ULTRA_10: any = { id: 2, name: "ULTRA_10" };
	public static readonly ULTRA_20: any = { id: 3, name: "ULTRA_20" };
	public static readonly ULTRA_50: any = { id: 4, name: "ULTRA_20" };

    public static getSubscriptionList() {
        return [SubscriptionEnum.BLOCKED, SubscriptionEnum.BASIC, SubscriptionEnum.ULTRA_10, SubscriptionEnum.ULTRA_20, SubscriptionEnum.ULTRA_50];
    }

	public static getSubscriptionByName(nameSubscription: string) {
		let subscriptionList: any[] = SubscriptionEnum.getSubscriptionList();
		return subscriptionList.find(subscription => subscription.name == nameSubscription);
	}

}
